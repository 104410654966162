exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-certification-js": () => import("./../../../src/pages/certification.js" /* webpackChunkName: "component---src-pages-certification-js" */),
  "component---src-pages-get-certified-js": () => import("./../../../src/pages/get-certified.js" /* webpackChunkName: "component---src-pages-get-certified-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-programme-js": () => import("./../../../src/pages/programme.js" /* webpackChunkName: "component---src-pages-programme-js" */)
}

